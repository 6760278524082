<template>
    <div class="travelDailyLog-list">
      <el-breadcrumb separator=">">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>
                <a href="#">校车管理</a>
            </el-breadcrumb-item>
            <el-breadcrumb-item>
                <a href="#">学生乘车记录</a>
            </el-breadcrumb-item>
        </el-breadcrumb>
      <el-divider></el-divider>
      <el-form ref="queryForm" :model="queryModel" inline class="demo-form-inline">
        <el-form-item label="学校/班级" prop="schoolId" label-width="80px">
            <!-- <el-cascader
            v-model="queryModel.schoolId"
            style="width:350px"
            placeholder="请选择"
            size="mini"
            :options="schoolClassList"
            change-on-select
            ></el-cascader> -->
        <el-select-tree
          size="mini"
          :options="schoolClassList"
          v-model="queryModel.schoolId"
          height="200"
        ></el-select-tree>
        </el-form-item>
        <el-form-item label="学生名称" prop="name" label-width="80px">
            <el-input v-model="queryModel.name" size="mini" style="width:100px"/>
        </el-form-item>
        <el-form-item label="车牌号" prop="licensePlateNumber" label-width="80px">
            <el-input v-model="queryModel.licensePlateNumber" size="mini" style="width:100px"/>
        </el-form-item>
        <!-- <el-form-item label="乘车时段" prop="classify" label-width="80px">
            <el-select v-model="queryModel.classify" placeholder="请选择" size="mini">
            <el-option
                v-for="item in timeRanges"
                :key="item.id"
                :label="item.lable"
                :value="item.value"
            ></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="选择线路" prop="routeId" label-width="80px">
            <el-select v-model="queryModel.routeId" placeholder="请选择" size="mini">
            <el-option
                v-for="item in routeList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
            ></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="选择班次" prop="scheduleId" label-width="80px">
            <el-select v-model="queryModel.scheduleId" placeholder="请选择" size="mini">
            <el-option
                v-for="item in scheduleList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
            ></el-option>
            </el-select>
        </el-form-item> -->
        <el-form-item label="乘车时间" prop="recordTime" label-width="80px">
        <el-date-picker
            v-model="queryModel.timeRanges"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd HH:mm:ss"
            size="mini"
          ></el-date-picker>
        </el-form-item>
        <el-form-item>
            <el-button
            type="primary"
            size="mini"
            icon="ios-search"
            @click="changePage(1)"
            :loading="loading"
            >查询</el-button>&nbsp;
            <el-button
                type="info"
                size="mini"
                style="margin-left: 8px"
                @click="handleReset('queryForm')"
            >重置</el-button>
        </el-form-item>
            
        </el-form>
        <el-divider></el-divider>
        <el-row class="button-group">
        <!-- <el-button
            type="primary"
            size="small"
            plain
            icon="el-icon-download"
            :loading="downloadLoading"
            @click="downloadXls"
        >导出数据</el-button> -->
        </el-row>
        <el-table
            ref="formTable"
            :data="tableData"
            v-loading="loading"
            element-loading-text="拼命加载中"
            stripe
            @selection-change="handleSelectionChange"
        >
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column prop="faceImageUrl" label="头像" width="80">
                <template slot-scope="{row}">
                <a :href="row.faceImageUrl" target="_blank">
                    <img
                    :src="row.faceImageUrl+'?x-oss-process=image/resize,mfit,w_120'"
                    :key="row.id"
                    style="object-fit:cover;width:60px;height:60px;border-radius:10px;"
                    />
                </a>
                </template>
            </el-table-column>
            <el-table-column prop="studentName" label="学生姓名" width="100"></el-table-column>
            <el-table-column prop="className" label="所属班级"></el-table-column>
            <el-table-column prop="schoolName" label="所属学校"></el-table-column>
            <el-table-column prop="temperature" label="测温记录" width="100"></el-table-column>
            <el-table-column prop="recordTime" label="乘车时间"></el-table-column>
            <el-table-column prop="location" label="上车地点">
                <template slot-scope="{row}">
                    <span @click="showMap(row)" style="cursor:pointer;">
                        <i class="el-icon-map-location"></i>{{row.location}}
                    </span>
                </template>
            </el-table-column>
            <el-table-column prop="licensePlateNumber" label="车牌号" width="100"></el-table-column>
            <!-- <el-table-column prop="routeName" label="线路" fixed="left"></el-table-column>
            <el-table-column prop="scheduleName" label="班次" fixed="left"></el-table-column>
            <el-table-column prop="scheduleType" label="班次类型" fixed="left"></el-table-column>
            <el-table-column prop="driverName" label="司机" fixed="left">
                <template slot-scope="{row}">
                    <div>{{row.driverName}}</div>
                    <div>{{row.driverPhone}}</div>
                </template>
            </el-table-column>
            <el-table-column prop="lifeguardName" label="照看员" fixed="left">
                <template slot-scope="{row}">
                    <div>{{row.lifeguardName}}</div>
                    <div>{{row.lifeguardPhone}}</div>
                </template>
            </el-table-column>
            <el-table-column prop="classTeacherName" label="班主任" fixed="left">
                <template slot-scope="{row}">
                    <div>{{row.classTeacherName}}</div>
                    <div>{{row.classTeacherPhone}}</div>
                </template>
            </el-table-column>
            <el-table-column prop="contactPhone" label="联系电话" fixed="left"></el-table-column> -->
        </el-table>
        <div style="text-align:center;">
            <el-pagination
                :current-page.sync="pageIndex"
                :total="totalElements"
                :page-sizes="pageSizeList"
                @current-change="changePage"
                @size-change="pageSizeChange"
                layout="total, sizes, prev, pager, next,  jumper"
            ></el-pagination>
        </div>

         <el-dialog
            :visible.sync="showMapDialog"
            title="乘车位置"
            :modal-append-to-body="true"
            :append-to-body="true"
            style="text-align:left;"
            :close-on-click-modal="false"
        >
            <el-amap
                ref="map"
                vid="vehicleMap"
                :center="pointPosition"
                :zoom="15"
                style="width:100%;height:400px;"
            >
                <el-amap-marker :position="pointPosition" :title="selectedRow.licensePlateNumber"></el-amap-marker>
            </el-amap>
            <el-input :value="pointPosition[0] + ',' + pointPosition[1]" :readonly="true"/>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showMapDialog=false;">关闭</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import travelDailyLogApi from '@/api/bus/travelDailyLog'
import registrationFeeApi from "@/api/bus/registrationFee";
import scheduleApi from "@/api/bus/schedule";
import routeApi from "@/api/bus/route";
import companyInfoApi from "@/api/base/companyInfo";
import SelectTree from "@/components/SelectTree";

export default {
    name:"busTravelDailyLogList",
    data(){
      return{
        queryModel:{
          name:"",
          schoolId:"",
          recordTime:"",
          routeId:"",
          classify:"",
          scheduleId:"",
          licensePlateNumber: "",
          timeRanges: null
        },
        routeList: [],
        scheduleList:[],
        schoolClassList: [],
        loading: false,
        tableData: [],
        multipleSelection: [],
        pageIndex: 1,
        pageSize: 10,
        totalElements: 0,
        pageSizeList: [10,20,30],
        downloadLoading: false,
        showMapDialog: false,
        selectedRow: {
            licensePlateNumber : "",
            deviceNo: ""
        },
        pointPosition: []
      }
    },
    methods: {
        changePage(pageIndex) {
            this.loading = true;
            var formData = new FormData();

            formData.append("pageIndex", this.pageIndex);
            formData.append("pageSize", this.pageSize);
            formData.append("name", this.queryModel.name);
            formData.append("schoolClassId", this.queryModel.schoolId);
            formData.append("licensePlateNumber", this.queryModel.licensePlateNumber);

            // formData.append("classify", this.queryModel.classify);
            // formData.append("routeId", this.queryModel.routeId);
            // formData.append("scheduleId", this.queryModel.scheduleId);

            if(this.queryModel.timeRanges!=null) {
                formData.append("beginTime", this.queryModel.timeRanges[0]);
                formData.append("endTime", this.queryModel.timeRanges[1]);
            }

            travelDailyLogApi.pageList(formData).then(response => {
                this.loading = false;
                var jsonData = response.data;

                console.log(jsonData);

                this.tableData = jsonData.data.data;
                this.totalElements = jsonData.data.recordsTotal;

                this.pageIndex = pageIndex;
            });
        },
        pageSizeChange(pageSize) {
            this.pageSize = pageSize;
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        handleReset(name) {
            this.$refs[name].resetFields();
        },
        downloadXls() {
            //导出
            var self = this;
            self.downloadLoading = true;
            self.loading = true;
            self.loadingText = "数据导出中，请稍后...";

            var formData = new FormData();

            formData.append("name", this.queryModel.name);
            formData.append("schoolClassId", this.queryModel.schoolId);
            // formData.append("classify", this.queryModel.classify);
            // formData.append("routeId", this.queryModel.routeId);
            // formData.append("scheduleId", this.queryModel.scheduleId);
            formData.append("recordTimeRanges", this.queryModel.recordTime);
            formData.append("licensePlateNumber", this.queryModel.licensePlateNumber);

            travelDailyLogApi.exportXls(formData).then(function(response) {
                var jsonData = response.data;
                self.downloadLoading = false;
                self.loading = false;
                if (jsonData.result) {
                //下载有错误信息提示的报表
                self.$message({
                    showClose: true,
                    dangerouslyUseHTMLString: true,
                    message: `报表已生成，<a href="${jsonData.data}">请点击链接下载</a>`,
                    duration: 60000,
                    type: "success"
                });
                } else {
                self.$message.error(jsonData.message);
                }
            });
        },
        showMap(row) {
            var arr = row.location.split(",");

            this.pointPosition = [parseFloat(arr[0]),parseFloat(arr[1])];
            this.selectedRow = row;

            this.showMapDialog = true;
        }
    },
    created() {
        var self = this;

        // routeApi.routeList().then(response => {
        //     var jsonData = response.data;
        //     this.routeList = jsonData.data;
        // });

        // scheduleApi.scheduleList().then(response => {
        //     var jsonData = response.data;
        //     this.scheduleList = jsonData.data;
        // });

        // registrationFeeApi.schoolClassList().then(response => {
        // var jsonData = response.data;
        // this.schoolClassList = jsonData.data;
        // });
        companyInfoApi.treeList().then(function (response) {
            var jsonData = response.data;

            if (jsonData.result) {
                self.schoolClassList = jsonData.data;
            }
        });
    },
    mounted() {
        this.changePage(1);
    },
    components: {
        "el-select-tree": SelectTree
    }
}
</script>
<style lang="scss" scoped>
.el-breadcrumb {
  margin: 10px;
  line-height: 20px;
}

.travelDailyLog-list{
    text-align: left;
}

.el-divider {
  margin: 5px 0;
}

</style>