import request from '@/utils/request'
import constant from '@/constant'

function pageList(formData){
    return request.post(constant.serverUrl + "/bus/travelDailyLog/pageList", formData);
}

function exportXls(formData) {
    //导出xls
    return request.post(constant.serverUrl + "/bus/travelDailyLog/exportXls", formData);
}

export default {
    pageList,exportXls
}